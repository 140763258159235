<template>
  <div>
    <div class="form-title">{{ title }}</div>
    <v-form ref="factoryForm" style="margin-top: 10px">
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="form.name"
              label="Name"
              class="text-field"
              outlined
              dense
              :rules="rules.name"
            ></v-text-field>
            <v-autocomplete
              v-model="form.establishedCountry"
              :items="countries"
              label="Established Country"
              dense
              outlined
              :menu-props="{
                zIndex: '204 !important',
              }"
              :rules="rules.establishedCountry"
            >
              <template v-slot:item="{ item }">
                <span class="country-selection-item">{{ item }}</span>
              </template>
              <template v-slot:selection="{ item }">
                <span class="country-selection-item">{{ item }}</span>
              </template>
            </v-autocomplete>
            <v-text-field
              v-model="form.establishedCity"
              label="Established City"
              class="text-field"
              outlined
              dense
              :rules="rules.establishedCity"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="right">
            <ui-button class="primary-outlined" @click="close" title="Close" />
            <ui-button
              class="primary"
              :loading="loading"
              @click="create"
              :title="isRequest ? 'Request' : 'Create'"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import countryList from "country-list";

export default {
  name: "FactoryForm",
  props: {
    factory: {
      type: Object,
      default: () => ({
        name: "",
        establishedCountry: null,
        establishedCity: "",
      }),
    },
    isRequest: {
      type: Boolean,
      default: false,
    },
    isBrand: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    factory: {
      handler(value) {
        this.form = { ...value };
      },
      immediate: true,
    },
  },
  computed: {
    countries() {
      return countryList.getNames();
    },
    title() {
      if (this.isRequest) {
        return this.isBrand ? "Request New Store" : "Request New Factory";
      } else {
        return this.isBrand ? "New Store" : "New Factory";
      }
    },
    rules() {
      return {
        name: [
          (v) =>
            !!v ||
            (this.validateAsBrand
              ? "Store name is required"
              : "Factory name is required"),
          (v) =>
            (v && v.length <= 200) ||
            (this.validateAsBrand
              ? "Store name must not exceed 200 characters"
              : "Factory name must not exceed 200 characters"),
        ],
        establishedCity: [
          (v) => !!v || "Established City is required",
          (v) =>
            (v && v.length <= 200) ||
            "Established city must not exceed 200 characters",
        ],
        establishedCountry: [(v) => !!v || "Established Country is required"],
      };
    },
  },
  data: () => ({
    form: {
      name: "",
      establishedCountry: null,
      establishedCity: "",
    },
  }),
  methods: {
    create() {
      if (!this.$refs.factoryForm.validate()) {
        return;
      }
      this.$emit("create", this.form);
    },
    close() {
      this.$refs.factoryForm.reset();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-title {
  font-size: 20px;
  font-weight: bold;
  color: #473068;
  padding: 10px 0px;
}
.v-text-field .v-label,
.v-text-field .v-input__control .v-input__slot input {
  color: #473068 !important;
}
.text-field::v-deep .v-messages__message {
  margin-left: -12px;
  color: #473068;
}
.text-field::v-deep .error--text .v-messages__message {
  color: $danger !important;
}
.v-autocomplete::v-deep label {
  color: #473068 !important;
}
.v-autocomplete::v-deep i {
  color: #473068 !important;
}
.v-autocomplete::v-deep .error--text .v-messages__message {
  margin-left: -12px;
  color: $danger !important;
}
.text-field::v-deep input,
.text-field::v-deep label {
  color: #473068 !important;
}
.v-text-field--outlined::v-deep fieldset {
  border: 1px solid #ccc;
  color: #473068;
}
.text-field-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
