<template>
  <div :style="isPublicPage ? 'margin-bottom: 20px;' : ''">
    <v-row
      id="bom-activity-log"
      style="position: relative; top: 0; z-index: 10"
    >
      <v-col class="header" cols="12">
        <span class="header-title">Activity Log</span>
        <v-icon
          @click="() => (logExpanded = !logExpanded)"
          class="header-title expand-icon"
          large
          >{{ logExpanded ? "expand_less" : "expand_more" }}</v-icon
        >
      </v-col>
    </v-row>
    <div v-if="loading && logExpanded" class="loading">
      <md-progress-spinner
        class="color_stroke"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div v-else-if="logExpanded">
      <v-row>
        <v-col class="search-header" cols="12">
          <v-col cols="6">
            <v-text-field
              v-model="searchQuery"
              label="Search"
              v-on:input="debounceSearch"
              class="search-input"
              dense
              clearable
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="searchBy"
              :items="filters"
              item-value="value"
              item-text="text"
              label="Search By"
              v-on:change="loadActivityLog"
              class="search-filter"
              dense
            ></v-select>
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="table-header" cols="12">
          <v-col cols="6">
            <span class="table-title"> Activity </span>
          </v-col>
          <v-col cols="3">
            <span class="table-title"> User Name </span>
          </v-col>
          <v-col cols="3">
            <span class="table-title"> Date </span>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-if="!bomLog.length">
        <v-col class="table-data" cols="12">
          <v-col cols="12">
            <div style="text-align: center">No activities found</div>
          </v-col>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="table-data" cols="12" v-for="log in bomLog" :key="log.id">
          <v-col cols="6">
            <span class="table-item">
              {{ log.message }}
            </span>
          </v-col>
          <v-col cols="3">
            <div class="brand-name-container">
              <span class="less-bold">
                {{
                  log.creator.brand_name ||
                  log.creator.first_name + " " + log.creator.last_name
                }}
              </span>
            </div>
          </v-col>
          <v-col cols="3">
            <span class="table-item less-bold">
              {{ new Date(log.created_at).toLocaleString() }}
            </span>
          </v-col>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <pagination
            :current-page="currentPage"
            :total-count="totalCount"
            @load-page="loadPage"
            v-if="bomLog.length"
          ></pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import notification from "../../notification";
import restAdapter from "../../restAdapter";
import Pagination from "../../components/Pagination";
import { bomDataBus } from "../../utils/bomDataBus";
import debounce from "lodash/debounce";
import routes from "../../router/routes";

export default {
  name: "BomLog",
  components: { Pagination },
  created() {
    bomDataBus.$on("refresh-log", this.refreshLog);
    window.addEventListener("resize", this.screenSizeHandler);
    window.addEventListener("scroll", this.screenScrollHandler);
  },
  destroyed() {
    window.removeEventListener("scroll", this.screenScrollHandler);
    window.removeEventListener("resize", this.screenSizeHandler);
  },
  computed: {
    isPublicPage() {
      return this.$route.name === routes.PUBLIC_BOM;
    },
  },
  data: () => ({
    loading: true,
    bomLog: [],
    filters: [
      { text: "All", value: "query" },
      { text: "Activity", value: "message" },
      { text: "User Name", value: "creator" },
    ],
    logExpanded: false,
    currentPage: 1,
    totalCount: 0,
    searchQuery: "",
    searchBy: "query",
    error: false,
    headerWidth: 0,
    scrollY: 0,
  }),
  mounted() {
    this.loadActivityLog();
  },
  methods: {
    refreshLog() {
      this.loadActivityLog(false);
    },
    loadActivityLog(indicateLoading = true) {
      if (indicateLoading) {
        this.loading = true;
      }
      let url = `/api/inquiries/${
        this.isPublicPage
          ? `public/${this.$route.params.styleNumber}`
          : this.$route.params.id
      }/bom_log?page=${this.currentPage}`;
      if (this.searchQuery) {
        url += `&${this.searchBy}=${this.searchQuery}`;
      }

      return restAdapter
        .get(url)
        .then((response) => {
          this.error = false;
          this.bomLog = response.data.data;
          this.totalCount = response.data.meta.total;
          const lastUpdate = response.data.meta.lastUpdated
            ? new Date(response.data.meta.lastUpdated).toLocaleString()
            : "";
          bomDataBus.$emit("last-update", lastUpdate);
          this.loading = false;
        })
        .catch((error) => {
          if (this.error) return;
          this.error = true;
          this.loading = false;
          if (error.response.status === 404) {
            notification.error("Style not found");
            if (!this.isPublicPage) {
              this.$router.push({ name: routes.COLLECTION_LIST });
            }
            this.$emit("invalid-style");
            return;
          }
          if (error.response.status === 403 || error.response.status === 401) {
            this.$router.push({ name: routes.COLLECTION_LIST });
            return;
          }
          if (!this.isPublicPage) {
            notification.error("Failed to load activity log");
          }
        });
    },
    loadPage(page) {
      this.currentPage = page;
      this.loadActivityLog();
    },
    screenSizeHandler(e) {
      this.windowSize = window.innerWidth;
      this.screenScrollHandler(e);
      return this.windowSize;
    },
    screenScrollHandler(e) {
      const logHeader = document.getElementById("bom-activity-log");
      const isPositionFixed = logHeader.style.position == "fixed";

      const logHeaderBounding = logHeader.getBoundingClientRect();
      this.headerWidth = logHeaderBounding.width;

      if (logHeaderBounding.top < 0 && !isPositionFixed) {
        this.scrollY = window.scrollY;
        logHeader.style.position = "fixed";
        logHeader.style.width = `${this.headerWidth}px`;
      } else if (window.scrollY < this.scrollY && isPositionFixed) {
        logHeader.style.position = "relative";
      }
    },
    debounceSearch: debounce(function () {
      this.loadActivityLog();
    }, 1000),
  },
};
</script>

<style scoped>
.loading {
  margin: 40px;
}
.color_stroke::v-deep .md-progress-spinner-circle {
  stroke: #492a67 !important;
}
.header-title {
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
}
.header-container {
  padding: 1px 25px 0px 4px;
}
.header {
  background-color: #473068;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
}
.expand-icon {
  margin: 0px 10px;
  background-color: rgb(255 255 255 / 12%);
  border-radius: 29px;
}
.search-header {
  justify-content: "space-between";
  align-items: center;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  background-color: white;
  display: flex;
  padding: 25px;
  text-align: left;
  height: 60px;
  margin: 30px 0px 10px 0px;
}
.table-header {
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  background-color: #47c0be;
  display: flex;
  text-align: left;
  min-height: 60px;
  margin: 20px 0px 10px 0px;
}
.table-title {
  font-weight: 600;
  font-size: 16px;
}
.table-data {
  justify-content: center;
  align-items: flex-start;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
  background-color: white;
  display: flex;
  text-align: left;
  min-height: 60px;
  margin: 10px 0px;
}
.table-item {
  font-weight: 600;
  font-size: 16px;
  color: #473068;
}
.less-bold {
  font-weight: 400;
  font-size: 16px;
}
.brand-name-container {
  text-align: center;
  background-color: #fff2f0;
  color: #ea7a66;
  width: fit-content;
  padding: 0px 12px;
  border-radius: 16px;
}
.search-input::v-deep .v-text-field__details {
  display: none;
}
.search-filter::v-deep .v-text-field__details {
  display: none;
}
</style>
