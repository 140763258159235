export const isNumberKey = (evt) => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    return !(charCode > 31 && (charCode < 48 || charCode > 57));
};

export const isDecimalKey = (evt, value) => {
    const charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode == 46 && value.indexOf(".") === -1) return true;
    return isNumberKey(evt);
}
