const SINGLE_FILE_MAX_SIZE = 1024 * 1024 * 50; // 50MB
const MULTIPLE_FILES_MAX_SUM = 1024 * 1024 * 100; // 100MB

export const validFilesSize = (files) => {
    let sum = 0;
    for (let i = 0; i < files.length; i++) {
        if (files[i].size > SINGLE_FILE_MAX_SIZE) {
        return false;
        }
        sum += files[i].size;
    }
    if (sum > MULTIPLE_FILES_MAX_SUM) {
        return false;
    }
    return true;
};
