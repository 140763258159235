const IMAGE_TYPES = ['png', 'image/png', 'jpg', 'image/jpg', 'jpeg', 'image/jpeg', 'webp', 'image/webp', 'svg', 'image/svg', 'gif', 'image/gif',];
const VIDEO_TYPES = ['mp4', 'video/ogg', 'webm', 'quicktime'];
const AUDIO_TYPES = ['mp3', 'audio/ogg', 'wav', 'mpeg'];

const checkMediaType = (types, file) => {
	if (!file || !(file.type || file.mime_type) ) return;
  if(!file.mime_type){
    return types.some(t => file.type.toLowerCase().includes(t));
  }
	return types.some(t => file.mime_type.toLowerCase().includes(t));
};

export const isImageFile = (file) => {
  return checkMediaType(IMAGE_TYPES, file);
};

export const isVedioFile = (file) => {
  return checkMediaType(VIDEO_TYPES, file);
};

export const isAudioFile = (file) => {
  return checkMediaType(AUDIO_TYPES, file);
};

